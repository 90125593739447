import React, { useState, useRef } from "react";
import sampleData from "../components/dummycv.json";
import NewResumePage from "../components/NewResumePage";
import styled from "styled-components";
import JSONInput from "react-json-editor-ajrm/index";
import ErrorBoundary from "../components/ErrorBoundary";
import locale from "react-json-editor-ajrm/locale/en";


const H1 = styled.h1`
  font-family: "GoodPro-Black";
`;

const H3 = styled.h3`
  font-family: "GoodPro-Black";
`;

const Overlay = styled.div`
  position: fixed;
  display: ${(props) => (props.showEditor ? "block" : "none")};
  width: 100%;
  height: 100%;
  top: 1%;
  left: 50%;
  right: 0;
  bottom: 1%;
  cursor: pointer;
  opacity: ${(props) => (props.showEditor ? "0.90" : "0.0")};
  transition: opacity 0.5s ease-out;
  transition: display 0.5s;
  z-index: 1;
`;

const EditorButton = styled.button`
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 100;
`;

const TitleBlock = styled.div`
  color: #231F20;
  background: #FBCCCD;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditorUI = () => {
  const [cvdata, setCVData] = useState(sampleData);
  const [showEditor, setShowEditor] = useState(false);
  const [error, setError] = useState(false);
  const json = useRef(null);

  const toggleShowEditorAndSaveDraft = () => {
    if(json.current.state.jsObject){
      setCVData(json.current.state.jsObject);
    }
    setShowEditor(!showEditor);
  };

  const updateErrorState = (error) => {
    setError(error);
  };
  return (
    <>
      <TitleBlock>
        <H1>Resume Draft</H1>
      </TitleBlock>
      <Overlay showEditor={showEditor}>
        <JSONInput
          colors={{
            string: "#DAA520",
          }}
          placeholder={cvdata}
          locale={locale}
          height="100%"
          width="100%"
          ref={json}
        />
      </Overlay>

      <EditorButton className="nav-button edit" onClick={() => toggleShowEditorAndSaveDraft()}>
        <H3>EDIT</H3>
      </EditorButton>
      <ErrorBoundary error={error} setError={updateErrorState}>
        <NewResumePage data={cvdata} />
      </ErrorBoundary>
    </>
  );
};

export default EditorUI

