import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import API from '../utils/api'
import Avatar from '../assets/Avatar.png'
import {
  faEnvelope,
  faMapMarker,
  faPhone,
  faUser,
  faPencilAlt,
  faCode,
  faGraduationCap,
  faCertificate,
  faTrophy,
  faChild,
  faBook,
  faTasks,
  faHeart,
  faThumbsUp,
  faLanguage,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import logo from '../assets/logo_orange.png'

const Container = styled.div`
  max-width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`

const H3 = styled.h3`
  font-family: 'GoodPro-Black';
`

const ResumePageContainer = styled.div`
  background: #fbcccd;
  padding: 1em 0em 5em 0em;
`

const MediaContainer = styled.div`
  max-width: 50%;
  @media screen and (min-width: 1400px) {
    max-width: 60%;
  }
  @media screen and (max-width: 650px) {
    max-width: 100%;
  }
`

const NewResumePage = ( {data} ) => {
  const state = data;
  const componentRef = useRef()
  const date_format = 'MMM YYYY'
  const _ = require('underscore')
  const _s = require('underscore.string')
  const moment = require('moment')
  const history = useHistory()

  var random =  1 + (Math.random() * (100-1));


  /*Curried dateformat function*/

  //provide format, altText and date, e.g. dateFormat(date-format)("placeholder txt")(date)
  const dateFormat = format => altText =>  date => {
    if(date){
      return moment(date).format(format);
    }
    return altText;
  }

  //partial application, provide altText and date
  const defaultDateFormatWithPlaceholder = dateFormat(date_format);

  //partial application, provide date
  const defaultDateFormatEmptyPlaceholder = dateFormat(date_format, "");


  const uploadResume = (state) => {
    API.postData('https://us-central1-orangit-cv.cloudfunctions.net/app/api/create', state)
    return history.push('/')
  }

  function getSocialSiteIcon(profile) {
    if (profile.network === 'Facebook') {
      return <FontAwesomeIcon icon={faFacebook} />
    } else if (profile.network === 'Twitter') {
      return <FontAwesomeIcon icon={faTwitter} />
    } else if (profile.network === 'Instagram') {
      return <FontAwesomeIcon icon={faInstagram} />
    } else if (profile.network === 'Linkedin') {
      return <FontAwesomeIcon icon={faLinkedin} />
    } else {
      return <FontAwesomeIcon icon={faGithub} />
    }
  }

  if (state.languages) {
    state.basics.languages = _.pluck(state.languages, 'language').join(', ')
  }
  _.each(state.work, (work_info) => {
    let did_leave_company
    const start_date = work_info.startDate && new Date(work_info.startDate)
    let end_date = work_info.endDate && new Date(work_info.endDate)

    did_leave_company = end_date

    if (start_date) {
      end_date = end_date || new Date()
      work_info.duration = humanizeDuration(
        moment.duration(end_date.getTime() - start_date.getTime()),
        did_leave_company
      )
    }
  })

  _.each(state.skills, (skill_info) => {
    const levels = ['Beginner', 'Intermediate', 'Advanced', 'Master']

    if (skill_info.level) {
      skill_info.skill_class = skill_info.level.toLowerCase()
      skill_info.level = _s.capitalize(skill_info.level.trim())
      skill_info.display_progress_bar = _.contains(levels, skill_info.level)
    }
  })

  state.skills = _.sortBy(state.skills, (skill) => {
    const level = skill.level && skill.level.toLowerCase()
    const sort_map = {
      master: 1,
      advanced: 2,
      intermediate: 3,
      beginner: 4,
    }

    return sort_map[level]
  })

  function humanizeDuration(moment_obj, did_leave_company) {
    let days
    const months = moment_obj.months()
    const years = moment_obj.years()
    const month_str = months > 1 ? 'months' : 'month'
    const year_str = years > 1 ? 'years' : 'year'

    if (months && years) {
      return `${years} ${year_str} ${months} ${month_str}`
    }

    if (months) {
      return `${months} ${month_str}`
    }

    if (years) {
      return `${years} ${year_str}`
    }

    if (did_leave_company) {
      days = moment_obj.days()

      return days > 1 ? `${days} days` : `${days} day`
    }
    return 'Recently joined'
  }

  return (
    <ResumePageContainer>
      <button
        className="nav-button back"
        onClick={() => history.goBack()}
        style={{
          position: 'absolute',
          right: '10px',
        }}
      >
        <H3> BACK</H3>
      </button>
      <button
        className="nav-button back"
        onClick={() => uploadResume(state)}
        style={{
          position: 'absolute',
          right: '90px',
        }}
      >
        <H3> UPLOAD</H3>
      </button>
      <Wrapper>
        <MediaContainer>
          <Container ref={componentRef}>
            <section className="row">
              <div className="card-wrapper">
                <div className="card">
                  <div className="logo">
                    <img alt="logo" src={logo} />
                  </div>
                  <div className="profile-card">
                    <div className="profile-pic" style={{display: 'table-cell', verticalAlign: 'top', padding: '1rem 4rem 0 0'}}>
                      {state.basics.image ? (
                        <img
                          className="media-object img-circle center-block"
                          data-src="holder.js/100x100"
                          alt={state.basics.name}
                          src={state.basics.image}
                          itemProp="image"
                        />
                      ) : (
                        <img
                          className="media-object img-circle center-block"
                          data-src="holder.js/100x100"
                          alt={state.basics.name}
                          src={Avatar}
                          itemProp="image"
                        />
                      )}
                    </div>
                    <div className="contact-details">
                      <div className="name-and-profession">
                        <h3 className="title" itemProp="name">
                          {' '}
                          {state.basics.name}
                        </h3>
                        <h5 className="text-muted" itemProp="jobTitle">
                          {state.basics.label}
                        </h5>
                      </div>

                      {state.basics.email && (
                        <div className="detail">
                          <FontAwesomeIcon className="icon" icon={faEnvelope} />
                          <span className="info">
                            <a
                              href={`mailto: ${state.basics.email}`}
                              className="link-disguise"
                              itemProp="email"
                            >
                              {state.basics.email}
                            </a>
                          </span>
                        </div>
                      )}
                      {state.basics.phone && (
                        <div className="detail">
                          <FontAwesomeIcon className="icon" icon={faPhone} />
                          <a
                            href={`tel://${state.basics.phone}`}
                            className="link-disguise info"
                            itemProp="telephone"
                          >
                            {state.basics.phone}
                          </a>
                        </div>
                      )}
                      {state.basics.location && (
                        <div className="detail">
                          <FontAwesomeIcon
                            className="icon"
                            icon={faMapMarker}
                          />
                          <span className="info">
                            {state.basics.location.city},{' '}
                            {state.basics.location.countryCode}
                          </span>
                        </div>
                      )}
                    </div>
                    <hr />
                    <div className="social-links">
                      {state.basics.profiles &&
                        state.basics.profiles.map((profile) => (
                          <a
                            className="social-link"
                            href={`${profile.url}/${profile.username}`}
                            target="_blank"
                            key={profile.url}
                            rel="noreferrer"
                          >
                            {getSocialSiteIcon(profile)}
                            <span className="text">{`${profile.url}/${profile.username}`}</span>
                          </a>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="row">
              <div className="card-wrapper">
                <div className="card background-card">
                  <h4 id="about">
                    <FontAwesomeIcon className="icon-left" icon={faUser} />
                    <span className="title">About</span>
                  </h4>
                  <div className="card-nested" itemProp="description">
                    <p> {state.basics.summary} </p>
                  </div>

                  <h4 id="work-experience">
                    <FontAwesomeIcon className="icon-left" icon={faPencilAlt} />
                    <span className="title">Work Experience</span>
                  </h4>
                  <ul className="list-unstyled">
                    {state.work.map((work) => (
                      <li key={work.position} className="card-nested">
                        <div className="content has-sidebar">
                          <p className="clear-margin-sm">
                            <strong>{work.position}</strong>,{' '}
                            {work.url ? (
                              <a
                                href={work.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {work.company || work.name}
                              </a>
                            ) : (
                              work.company || work.name 
                            )}
                          </p>
                          <p className="text-muted">
                            <small>
                              <span className="space-right">
                                {defaultDateFormatEmptyPlaceholder(work.startDate)} - {defaultDateFormatWithPlaceholder("Present", work.endDate)}
                              </span>
                              {work.duration && moment.isDuration(work.duration) && (
                                <span>
                                  <i className="fa fa-clock-o icon-left" />
                                  {work.duration}
                                </span>
                              )}
                            </small>
                          </p>
                          <p>{work.summary}</p>
                          {work.highlights && (
                            <ul>
                              {work.highlights.map((highlight) => (
                                <li className="text-muted" key={highlight}>
                                  {' '}
                                  {highlight}{' '}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>

                  {state.skills && state.skills.length > 0 && (
                  <>
                  <h4 id="skills">
                    <FontAwesomeIcon className="icon-left" icon={faCode} />
                    <span className="title">Skills</span>
                  </h4>
                  <ul className="list-unstyled">
                    {state.skills.map((skill) => (
                      <li
                        key={skill.name}
                        className={`card-nested skill ${skill.level.toLowerCase()}`}
                      >
                        <strong>
                          {skill.name} ({skill.level}
                          ):{' '}
                        </strong>
                        {skill.keywords &&
                          skill.keywords.map((keyword) => (
                            <span key={keyword+random} className="enumeration">
                              {keyword}
                            </span>
                          ))
                        }
                      </li>
                    ))}
                  </ul>
                  </>
                  )}

                  {state.education && state.education.length > 0 && (
                  <>
                  <h4 id="education">
                  <FontAwesomeIcon
                    className="icon-left"
                    icon={faGraduationCap}
                  />
                  <span className="title">Education</span>
                  </h4>

                  <ul className="list-unstyled">
                    {state.education.map((educ) => (
                      <li className="card-nested" key={educ.startDate}>
                        <div
                          key={educ.institution}
                          className="content has-sidebar"
                        >
                          <p className="clear-margin-sm">
                            <strong>
                              {educ.area}, {educ.studyType},
                            </strong>
                            &nbsp;
                            {educ.institution}
                          </p>
                          <p className="text-muted">
                            <small>
                              {defaultDateFormatEmptyPlaceholder(educ.startDate)} -
                              {defaultDateFormatWithPlaceholder("Currently Pursuing")(educ.endDate)}
                            </small>
                          </p>
                          <div className="space-top labels">
                            {educ.courses &&
                              educ.courses.map((course) => (
                                <span
                                  key={course}
                                  className="label label-keyword"
                                >
                                  {course}
                                </span>
                              ))
                            }
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  </>
                  )}

                  {state.certificates && state.certificates.length > 0 && (
                    <>
                      <h4 id="certificates">
                        <FontAwesomeIcon
                          className="icon-left"
                          icon={faCertificate}
                        />
                        <span className="title">Certificates</span>
                      </h4>
                      <ul className="list-unstyled">
                        {state.certificates.map((certificate) => (
                          <li key={certificate.date} className="card-nested">
                            <div className="content has-sidebar">
                              <p
                                className="clear-margin-sm"
                                itemProp="certificates"
                              >
                                {certificate.url ? (
                                  <a
                                    href={certificate.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {certificate.name}
                                  </a>
                                ) : (
                                  certificate.issuer
                                )}
                                ,&nbsp;
                                {certificate.issuer}
                              </p>
                              <p className="text-muted">
                                <small>
                                  {' '}
                                  Issued on: {defaultDateFormatEmptyPlaceholder(certificate.date)}
                                </small>
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {state.awards && state.awards.length > 0 && (
                    <>
                      <h4 id="awards">
                        <FontAwesomeIcon
                          className="icon-left"
                          icon={faTrophy}
                        />
                        <span className="title">Awards</span>
                      </h4>
                      <ul className="list-unstyled">
                        {state.awards.map((award) => (
                          <li key={award.title} className="card-nested">
                            <div className="content has-sidebar">
                              <p className="clear-margin-sm" itemProp="award">
                                <strong>{award.title}</strong>
                                ,&nbsp;
                                {award.awarder}
                              </p>
                              <p className="text-muted">
                                <small> Awarded on: {defaultDateFormatEmptyPlaceholder(award.date)}</small>
                              </p>
                              <p> {award.summary} </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {state.volunteer && state.volunteer.length > 0 && (
                    <>
                      <h4 id="volunteer-work">
                        <FontAwesomeIcon className="icon-left" icon={faChild} />
                        <span className="title">Volunteer Work</span>{' '}
                      </h4>
                      <ul className="list-unstyled">
                        {state.volunteer.map((volunteer) => (
                          <li key={volunteer.startDate} className="card-nested">
                            <div className="content has-sidebar">
                              <p className="clear-margin-sm">
                                <strong>{volunteer.position}</strong>
                                ,&nbsp;
                                {volunteer.organization}
                              </p>
                              <p className="text-muted">
                                <small>
                                  {defaultDateFormatEmptyPlaceholder(volunteer.startDate)} - {defaultDateFormatWithPlaceholder("Present")(volunteer.endDate)}
                                </small>
                              </p>
                              <p>{volunteer.summary}</p>
                              <ul>
                                {volunteer.highlights.map((highlight) => (
                                  <li key={highlight}>{highlight} </li>
                                ))}
                              </ul>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {state.publications && state.publications.length > 0 && (
                    <>
                      <h4 id="publications">
                        <FontAwesomeIcon className="icon-left" icon={faBook} />
                        <span className="title">Publications</span>{' '}
                      </h4>
                      <ul className="list-unstyled">
                        {state.publications.map((publication) => (
                          <li key={publication.name} className="card-nested">
                            <div className="content has-sidebar">
                              <p className="clear-margin-sm">
                                <strong>
                                  {publication.url ? (
                                    <a
                                      href={publication.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {publication.name}
                                    </a>
                                  ) : (
                                    publication.name
                                  )}
                                </strong>
                                ,&nbsp;
                                {publication.publisher}
                              </p>
                              <p className="text-muted">
                                <small>
                                  {' '}
                                  Published on: {defaultDateFormatEmptyPlaceholder('MMM DD, YYYY', publication.releaseDate)}{' '}
                                </small>
                              </p>
                              <p className="clear-margin">
                                {publication.summary}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {state.projects && state.projects.length > 0 && (
                    <>
                      <h4 id="projects">
                        <FontAwesomeIcon className="icon-left" icon={faTasks} />
                        <span className="title">Projects</span>{' '}
                      </h4>
                      <ul className="list-unstyled">
                        {state.projects.map((project) => (
                          <li key={project.name} className="card-nested">
                            <div className="content has-sidebar">
                              <p className="clear-margin-sm">
                                <strong>
                                  {project.url ? (
                                    <a
                                      href={project.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {project.name}
                                    </a>
                                  ) : (
                                    project.name
                                  )}
                                </strong>
                                ,&nbsp;
                                {project.type}
                              </p>
                              <p className="text-muted">
                                <small>
                                  {defaultDateFormatEmptyPlaceholder(project.startDate)} -
                                  {defaultDateFormatWithPlaceholder("Present", project.endDate)}
                                </small>
                              </p>
                              <p className="clear-margin">
                                {project.description}
                              </p>
                              {project.keywords && project.keywords.map((keyword) => (
                                <span key={keyword+random}  className="label label-keyword">
                                  {keyword}
                                </span>
                              ))}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {state.interests && state.interests.length > 0 && (
                    <>
                      <h4 id="interests">
                        <FontAwesomeIcon className="icon-left" icon={faHeart} />
                        <span className="title">Interests & motivations</span>
                      </h4>
                      <ul className="list-unstyled">
                        {state.interests.map((interest) => (
                          <li key={interest.name} className="card-nested">
                            <p>
                              <strong>{interest.name}</strong>
                            </p>

                            <div className="space-top labels">
                              {interest.keywords.map((keyword) => (
                                <span
                                  key={keyword+random}
                                  className="label label-keyword"
                                >
                                  {keyword}
                                </span>
                              ))}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {state.references && state.references.length > 0 && (
                    <>
                      <h4 id="references">
                        <FontAwesomeIcon
                          className="icon-left"
                          icon={faThumbsUp}
                        />
                        <span className="title">References</span>{' '}
                      </h4>
                      <ul className="list-unstyled">
                        {state.references.map((reference) => (
                          <li key={reference.name} className="card-nested">
                            <p>
                              <strong>{reference.name}</strong>
                            </p>
                            <blockquote className="quote">
                              <p className="clear-margin">
                                {reference.reference}
                              </p>
                            </blockquote>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  {state.languages && state.languages.length > 0 && (
                    <>
                      <h4 id="languages">
                        <FontAwesomeIcon
                          className="icon-left"
                          icon={faLanguage}
                        />
                        <span className="title">Languages</span>
                      </h4>
                      <p className="card-nested">
                        {state.languages.map((language) => (
                          <span key={language.language} className="enumeration">
                            <strong>{language.language}</strong> 
                            ({language.fluency})
                          </span>
                        ))}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </section>
          </Container>
        </MediaContainer>
      </Wrapper>
    </ResumePageContainer>
  )
}

export default NewResumePage
