import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import firebase from '../firebase'
import { db } from '../firebase'
import Avatar from '../assets/Avatar.png'
import Footer from './Footer'

const ResumesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 200px 0 200px;

  @media screen and (max-width: 700px) {
    display: block;
  }
`

const ResumeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem;
  flex: 0 calc(50% - 2 * 4rem);
`

const Wrapper = styled.div`
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`

const Resumes = () => {
  const [resumes, setResumes] = useState([])
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        console.log('You need to sign in first')
        window.location = '/'
      } else if (user.email.includes('orangit.fi')) {
        setLoggedIn(true)
        db.collection('resumes')
          .get()
          .then((querySnapshot) => {
            setResumes(querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()})))
            setLoading(false)
          })
      }
    })
  }, [])

  return (
    <Wrapper>
      {isLoggedIn && (
        <>
          {isLoading ? (
            <div className="loader">Loading</div>
          ) : (
            <>
              <ResumesContainer>
                {resumes.map((resume) => (
                  <ResumeWrapper key={resume.data.basics.name}>
                    <Link
                      to={{ pathname: `/cv/${resume.id}`, state: resume.data }}
                      style={{
                        textDecoration: 'none',
                        color: 'white',
                        alignItems: 'center',
                      }}
                    >
                      {resume.data.basics.image ? (
                        <img
                          className="rounded"
                          src={resume.data.basics.image}
                          alt={resume.data.basics.name}
                        />
                      ) : (
                        <img 
                          className="rounded"
                          src={Avatar} alt="Avatar" 
                        />
                      )}
                      <div className="employee-info">
                        <h3 className="card-text">{resume.data.basics.name}</h3>
                        <h4 className="card-text">{resume.data.basics.label}</h4>
                      </div>
                    </Link>
                  </ResumeWrapper>
                ))}
              </ResumesContainer>
              <Footer />
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default Resumes
