import React from 'react'
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const FooterContainer = styled.div`
  padding: 2.5rem 3rem;
`

const Level = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
`
const Left = styled.div`
  align-items: center;
  justify-content: flex-start;
`
const Content = styled.div`
  color: white;
`
const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
`

const Footer = () => {
  return (
    <FooterContainer>
      <Level>
        <Left>
          <Content>
            <p>hello@orangit.fi</p>
            <p>Salomonkatu 17A, 00100 Helsinki, FINLAND</p>
          </Content>
        </Left>
        <Content>
          <IconContainer>
            <a href="https://www.linkedin.com/company/orangit-oy/">
              <FontAwesomeIcon id="footer-icon" icon={faLinkedin} />
            </a>
            <a href="https://www.facebook.com/orangithq/">
              <FontAwesomeIcon id="footer-icon" icon={faFacebookF} />
            </a>
            <a href="https://twitter.com/OrangitOy">
              <FontAwesomeIcon id="footer-icon" icon={faTwitter} />
            </a>
            <a href="https://www.instagram.com/orangithq/">
              <FontAwesomeIcon id="footer-icon" icon={faInstagram} />
            </a>
          </IconContainer>
        </Content>
      </Level>
    </FooterContainer>
  )
}

export default Footer
