import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../assets/logo_white.png'

const StyledLogo = styled.img`
  width: 100%;
  height: auto;
  max-width: 400px;
  @media screen and (max-width: 650px) {
    width: 50%;
    height: auto;
  }
`
const H3 = styled.h3`
  font-family: 'GoodPro-Black';
`

const NavBar = ({ user, onClick, buttonName, backgroundColor }) => {

  const history = useHistory();
  const openCreateView = () => {
    history.push('/create')
  }

  return (
    <div
      style={{
        background: backgroundColor,
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      }}
    >
      <StyledLogo src={logo} alt="Logo" />
      {user &&
        <button className="nav-button" onClick={openCreateView}>
          <H3>Create</H3>
        </button>
      }
      {onClick && (
        <button className="nav-button" onClick={onClick}>
          <H3>{buttonName}</H3>
        </button>
      )}

    </div>
  )
}

export default NavBar
