import React from "react";
import styled from "styled-components";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import Signup from "./components/SignUp";
import history from "./history";
import ResumePage from "./components/ResumePage";
import EditorUI from "./components/EditorUI";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
`;

function App() {
  // Disabled for testing
  return (
    <BrowserRouter>
      <Router history={history}>
        <Wrapper>
          <Switch>
            <Route exact path="/" component={Signup} />
            <Route exact path="/create" component={EditorUI} />
            <Route path="/cv/:id" component={ResumePage} />
          </Switch>
        </Wrapper>
      </Router>
    </BrowserRouter>
  );
}

export default App;
