import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    if (props.setError === undefined) {
      this.state = { hasError: true };
    } else {
      this.state = { hasError: false };
    }
  }

  static getDerivedStateFromError(error) {
    console.log("ERROR ERROR", error);
    // Update state so the next render will show the fallback UI.
    this.props.setError && this.props.setError(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("Error caught: ", error, errorInfo);
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);

    if (this.props.setError) {
      this.props.setError(errorInfo);
      this.setState = { hasError: true };
      console.log("error did catch");
    }
  }

  render() {
    if (this.state.hasError || this.props.error) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
