import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import * as firebaseui from 'firebaseui'
import Resumes from './Resume'
import NavBar from './NavBar'

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        hd: 'orangit.fi',
        prompt: 'select_account',
      },
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult() {
      return false
    },
  },
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
}

const signOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      console.log('Successufully Signed out')
    })
    .catch(() => {
      console.log('Error Signed out')
    })
}

const Signup = () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const authObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email.endsWith('orangit.fi')) {
        setUser(user)
      } else if (user) {
        user.delete().then(() => {
          setUser(null)
          window.location.reload()
        })
      }
    })
    return authObserver
  }, [user])

  if (!user) {
    return (
      <>
        <div className="fill-window">
          <NavBar user={user}/>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      </>
    )
  }
  return (
    <>
      <div>
        <NavBar
          user={user}
          onClick={signOut}
          buttonName="LOG OUT"
          backgroundColor="#231F20"
        />
        <Resumes />
      </div>
    </>
  )
}

export default Signup
