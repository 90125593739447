import firebase from '../firebase'
// Example POST method implementation:
async function postData(url = "", data = {}) {
    const token = await firebase.auth().currentUser.getIdToken();
    const resumeData = JSON.stringify({ data: data });
    console.log("simulating api call to ", url, " for: ", resumeData);
    
    // Default options are marked with *
    const response = await fetch(url, {
        method: "POST", 
        mode: "cors", 
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
        },
        body: resumeData,
    })
    return response
    
}


const API = { postData: postData }

export default API
